





































import useRole from "@/use/role";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    OUserList: () =>
      import("@/components/organisms/panel/user/o-user-list.vue"),
  },

  setup(_, { root }) {
    const { userRole } = useRole({ root });

    const state = reactive({
      search: "",
      role: null,
      roleItems: [{ text: "Wszystkie", value: null }, ...userRole],
    });

    return { state, userRole };
  },
});
